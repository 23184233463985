import React from 'react';
import circle from '../../node_modules/uswds/dist/img/circle-124.png';
import DataWrapChart from './DataWrapper';
/*
  Use this section to highlight key elements of your site. Some sites will only have two while others may have six to eight. 
*/

const Highlights = () => (
  <section className="usa-graphic-list usa-section usa-section">
    <div className="grid-container">
    <h1 className="font-heading-2xl margin-top-0 margin-bottom-0">
        Highlighted Data
        </h1>
        <p style={{"color":"#719f2a"}} className="tablet:margin-bottom-6 margin-top-1"> <strong>See Data Trends on the navigation bar for more data.</strong></p>
      <div className="usa-graphic-list__row grid-row grid-gap">
        <div className="usa-media-block tablet:grid-col">
          <div className="usa-media-block__body">
            
          <DataWrapChart maxHeight="500px"  title="Criminal Case Filings by Type" aria-label="Interactive line chart" id="datawrapper-chart-CEFlD" src="https://datawrapper.dwcdn.net/rqCh9/" scrolling="no" frameborder="0" ></DataWrapChart>
          </div>
        </div>
        <div className="usa-media-block tablet:grid-col">
          <div className="usa-media-block__body">
            <DataWrapChart  title="Felony APP Participants by Race" aria-label="Interactive line chart" id="datawrapper-chart-gLF1F" src="https://datawrapper.dwcdn.net/rvIMo/5/" scrolling="no" frameborder="0" ></DataWrapChart>
          </div>
        </div>
      </div>
    
    </div>
  </section>
);

export default Highlights;
