import React from 'react';
import homepagePic from "../images/desktop_text.jpg";

/*
  This will be displayed on the homepage. Ideally, you want to highlight key goals of the website
*/

const Hero = () => (
  <section className="">
    <div className="">
    <div class="grid-flex-align-stretch">
    <img
          src={homepagePic}
          
        >
        </img>

      </div>
    </div>
  </section>
);

export default Hero;
