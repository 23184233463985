import React from 'react';

/*
  This tagline will appear in your homepage
*/

const Tagline = () => (
  <div className="usa-section--light">
  <section className="grid-container usa-section usa-prose ">
    <div className="grid-row grid-gap">
      <div className="tablet:grid-col-4">
        <h2 className="font-heading-xl margin-top-0 tablet:margin-bottom-0">
        Opening Lake County Criminal Justice Data to the Public
        </h2>
      </div>
      <div className="tablet:grid-col-8 usa-prose">
        <p>
        Effective reform comes with facts and data and won't be successful unless it’s embraced by those who have the power to change the system.
        </p>
        <p>
        To build trust and buy-in from the community and our partners, we must be able to measure, analyze, and present data in a way that is public, transparent, and accountable.
        </p>
      </div>
    </div>
  </section>
  </div>
);

export default Tagline;
